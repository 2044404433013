import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Main } from "page_components/404"

const NotFoundPage = () => {
  const title = "404"

  return (
    <Layout seo={{ title: title }}>
      <Breadcrumbs title={title} />
      <PageHeader title="Błąd #404" />
      <Main />
    </Layout>
  )
}

export default NotFoundPage
